<template>
  <div class="page">
    <div class="list">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item"
        @click="goDetail(item)">
        <div class="title">{{item.title}}</div>
        <div class="text" v-html="item.text"></div>
        <div class="date">{{item.date}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      list: []
    }
  },
  created() {
    this.basePage()
  },
  methods: {
    basePage() {
			// 获取数据
			switch (this.$i18n.locale) {
				case 'zh': // 中
          this.list = this.$config.newsListZH
					break
				case 'en': // 英
          this.list = this.$config.newsListEN
					break
			}
		},
    goDetail (item) { // 详情
      this.$router.push({path: '/news', query: {id: item._id}})
    }
  }
}
</script>
<style scoped>
.list{
  padding-top: 10px;
}
.item{
  padding: 20px 0;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;
}
.title{
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
}
.text{
  font-size: 15px;
  line-height: 20px;
  color: #666;
}
.text_link{
  color: #14a6a3;
}
.date{
  margin-top: 20px;
  width: 100%;
  text-align: right;
  color: #999;
}
</style>
